import React from 'react';
import App, { Container } from 'next/app';
import UserAPI from '../api/UserAPI';
import { Provider } from 'react-redux';
import {
  overwrite,
  overwriteAppDomain,
  initializeStore,
  putAuth,
  baseServerConfig,
  setGuideId,
} from '../store';
import withRedux from 'next-redux-wrapper';
import * as Sentry from '@sentry/browser';
import getConfig from 'next/config';
import Router from 'next/router';
import 'bootstrap/dist/css/bootstrap.min.css';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

Sentry.init({
  dsn: publicRuntimeConfig.SENTRY_DSN,
});

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    var pageProps = {};
    var auth_token = null;
    var _user = null;
    var userAgent = null;
    var appDomain = 'witheta';
    var guideId = null;
    if (ctx.isServer) {
      userAgent = ctx.req.get('User-Agent');
      const hostParts = ctx.req.host.split('.');
      var indexer = hostParts.length > 1 ? 2 : 1;
      appDomain = hostParts[hostParts.length - indexer];
      if (appDomain !== 'witheta' && appDomain !== 'localhost') {
        appDomain = 'movement';
      }
      ctx.store.dispatch(overwriteAppDomain(appDomain));
      const cookies = ctx.req.cookies;
      if (cookies != null) {
        guideId = cookies.guideId;
        if (guideId) {
          ctx.store.dispatch(setGuideId(guideId));
        }
        const auth = cookies.token;
        auth_token = auth;
        const userApi = new UserAPI();
        userApi.setAuth(auth_token);
        const user = await userApi.getMe();
        var shouldDispatchAuth = true;
        if (user !== null && user.code !== -1) {
          ctx.store.dispatch(overwrite(user));
          _user = user;
        } else {
          if (user.error && user.error.detail == 'Invalid token.') {
            console.log('Recieves invalid token. Not dispatching auth');
            shouldDispatchAuth = false;
            auth_token = null;
          }
        }
        if (shouldDispatchAuth) {
          ctx.store.dispatch(putAuth(auth_token));
        }
      }
    }

    if (Component.getInitialProps) {
      ctx.auth = auth_token;
      ctx.user = _user;
      ctx.appDomain = appDomain;
      ctx.guideId = guideId;
      ctx.userAgent = userAgent;
      pageProps = await Component.getInitialProps(ctx);
    }

    pageProps.auth = auth_token;
    pageProps.user = _user;
    pageProps.appDomain = appDomain;
    pageProps.userAgent = userAgent;

    return { pageProps };
  }

  componentDidMount() {
    baseServerConfig.add(this.props.store.getState().appDomain);

    Router.beforePopState(({ as }) => {
      location.href = as;
    });
  }

  render() {
    const { Component, pageProps, store } = this.props;

    return (
      <Container>
        <Provider store={store}>
          <Component {...pageProps} />
        </Provider>
      </Container>
    );
  }
}

export default withRedux(initializeStore)(MyApp);
